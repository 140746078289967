<template>
  <section id="map">
    <card
      icon="map"
      :headline="$t('visitors.map.headline')"
      :description="$t('visitors.map.description')"
    >

    </card>
  </section>
</template>

<script>
  export default {
    name: "Map"
  }
</script>

<style scoped lang="scss">

</style>
